#enterButton {
    color: black !important;
    position: relative !important;
    justify-self: center;
    top: 20svh !important;
    font-size: 2em;
}

.howToTxt {
    position: absolute;
    top: 10svh;
    background-image: url("/public/images/HowToParticipate.png");
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 7svh;
}

.line {
    position: absolute;
    background-image: url("/public/images/Line.png");
    background-size: cover;
    height: .4svh;
    width: 100vw;
    top: 27.8svh;
    left: 50%;
    transform: translateX(-50%);
}

.steps {
    position: relative;
    top: 18svh;
    background-image: url("/public/images/steps.png");
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 50svh;
}
