.verifyOtpText {
    position: relative;
    top: 10svh;
    background-image: url("/public/images/verifyOtpText.png");
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 12svh;
}

#otpInput {
    color: white;
    position: absolute;  
    
    height: 5svh;
    width: 35svh;
    font-size: 1.5svh;
    background-image: url("/public/images/inputFieldBG.png");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
    padding: 0 2svh 0 2svh;
    
    margin-top: 18svh;
    left: 50%;
    transform: translateX(-50%);
}

::placeholder {
    color: white;
    opacity: 1;
}

#resendBtn{
    position: absolute;
    font-size: 1.5svh;
    height: 5svh;
    width: 40svh;
    color: white;
    left: 50%;
    transform: translateX(-50%);    
    text-align: center;
    bottom: 35svh;

}

#verifyOtpBtn {        
    bottom: 25svh;
    font-size: 1svh;
}
