.tapToUpload{
    position: relative;
    top: 18svh;
    background-image: url("/public/images/TapToUpload.png");
    background-size: auto 100%;   
    background-position: center; 
    background-repeat: no-repeat;
    height: 28svh;
}

.uploadText{
    position: relative;
    top: 15svh;
    background-image: url("/public/images/DataUploadTxt.png");
    background-size: auto 100%;   
    background-position: center; 
    background-repeat: no-repeat;
    height: 15svh;
}

input{
    position: absolute;

}

#submitVideo
{
    position: relative !important;
    justify-self: center;
    top: 22svh;
    /* bottom: 15svh;        */
    font-size: 48px;
}

.progressBarOuter
{    
    position: absolute;  
    display: flex;
    align-items: center;
    justify-content: start;
    background-image: url("/public/images/LoadingBarBG.png"); 
    background-repeat: no-repeat; 
    background-size: cover;
    background-size: 100% 100%;
    color: white; 
    text-align: center;             
    width: 30svh;
    height: 5svh;
    font-size: 2.5svh;            
    left: 50%;
    top: 55%;
    transform: translate(-50%, -100%);
}

.progressBarOuterUpper
{    
    z-index: 10;
    margin-left: .5svh;    
    position: absolute;  
    display: flex;
    align-items: center;
    justify-content: start;
    background-image: url("/public/images/LoadingBar.png"); 
    background-repeat: no-repeat; 
    background-size: cover;
    background-size: 100% 100%;
    color: white; 
    text-align: center;             
    width: 29svh;
    height: 4svh;
    font-size: 2.5svh;            

}

.progressBarText{
    position: absolute;  
    font-family: AldotheApache, sans-serif;  
    color: white; 
    text-align: center;             
    width: 80svw;
    height: 10svh;
    font-size: 2.5svh;            
    left: 50%;
    top: 65%;
    transform: translate(-50%, -50%);
}

.largerText{
    font-size: 3.5svh !important;
}

#selectedFileText{
    bottom: 8svh;
    display: none;
    position: relative;
    left: 50%;
    z-index: 15;    
    transform: translate(-50%, -50%);
    color: white;
    font-size: 1.8svh;
    width: 15svh;    
    padding-top: 0.5svh;
    padding-bottom: 0.5svh;
    text-align: center;
    font-family: HelveticaNeueBold, sans-serif;    
    text-align: center;
    background-color: rgb(0, 138, 0);
    margin-left: 1svh;

}

#UploadText{
    position: absolute;
    color: white;
    font-size: 2svh;
    left: 50%;
    transform: translateX(-50%);
    font-family: HelveticaNeueBold, sans-serif; 
    bottom: 11svh;
    text-align: center;
    margin-left: 1svh;    
    width: 28svh;

}

.progressBarInner
{
    /* display: none; */
    z-index: 5;
    position: relative;
    margin-left: 1svh;
    margin-right: 1svh;
    width: 80%;
    height: 80%;
    background-image: linear-gradient(to bottom, #00ded7ce, #ded700ce);
    transform: skewX(-4deg);
    
}