@font-face {
    font-family: "AldotheApache";
    src: url("/public/fonts/AldotheApache.ttf");
    format: ("ttf");
}

@font-face {
    font-family: "HelveticaNeueBold";
    src: url("/public/fonts/Helvetica/Helvetica/Helvetica\ Neue\ Bold.ttf");
    format: ("ttf");
}

.panel {
    position: relative;
    height: 100svh;
    width: 100vw;
}

body {
    background-color: #0B0D3E;
    overflow: hidden;
    width: 100vw;
}

input:focus {
    outline: none;
}


button {
    position: absolute;
    color: black !important;
    z-index: 10;
    font-family: HelveticaNeueBold, sans-serif;
    font-size: 2.2svh !important;
    background-image: url("/public/images/TooYummButton.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    width: 30svh;
    height: 10svh;
    background-color: transparent;
    left: 50%;
    transform: translateX(-50%);
}

.button {
    position: absolute;
    color: black;
    z-index: 10;
    font-family: HelveticaNeueBold, sans-serif;
    font-size: 2.2svh !important;
    background-image: url("/public/images/TooYummButton.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    width: 30svh;
    height: 10svh;
    background-color: transparent;
    left: 50%;
    transform: translateX(-50%);
}

#container {
    height: 100svh; /* Adjust the height as needed */
    position: absolute;
    background-color: #0B0D3E;
    background-image: url("/public/images/BgUpdated.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
}

.screen {
    /* position: absolute;
    left: 50%;
    transform: translateX(-50%);*/
    /* width: 100vw;      */
    z-index: 10;    
    overflow-y: scroll !important;
    overflow-x: scroll;
    display: flex;
    justify-content: center;
    -webkit-overflow-scrolling: touch;
}

.leftElements {
    pointer-events: none;
    /* z-index: 1; */
    height: 100svh;
    width: 10svh;
    position: absolute;
    left: 0;
    background-image: url("/public/images/LeftElements.png");
    background-repeat: no-repeat;
    background-size: cover;
    animation: moveDownUp 20s linear infinite alternate;
}

@keyframes moveUpDown {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10svh);
    }
}

@keyframes moveDownUp {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(10svh);
    }
}

.rightElements {
    pointer-events: none;
    /* z-index: 1; */
    height: 100svh;
    top: 25svh;
    width: 8svh;
    position: absolute;
    right: 0;
    background-image: url("/public/images/RightElements.png");
    background-repeat: no-repeat;
    background-size: contain;
    animation: moveUpDown 20s ease-in-out infinite alternate;
}

.shareBtn {
    z-index: 20;
    position: relative;
    margin-right: 3svh;
    width: 3svh;
    height: 3svh;
    background-image: url("/public/images/ShareIcon.png");
    background-size: 100% 100%;
}

.navBar {
    z-index: 9;
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 47svh;
    height: 5svh;
    top: 4svh;
    left: 50%;
    transform: translateX(-50%);
}

.blankSpace
{
    height: 5svh;
}

.backBtn {
    z-index: 10;
    position: relative;
    margin-left: 3svh;
    width: 3svh;
    height: 3svh;
    background-image: url("/public/images/Backicon.png");
    background-size: 100% 100%;
}


.tooyumxbb {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    top: 8svh;
    background-image: url("/public/images/TY-x-BB.png");
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 15svh;
}

.tooyumxbbParticipate {
    position: relative;
    top: 8svh;
    background-image: url("/public/images/TY-x-BB.png");
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 15svh;
}


.tooyumxbbHome {
    position: relative;
    top: 8svh;
    background-image: url("/public/images/TY-x-BB.png");
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 28svh;
}

.hidden {
    display: none;
}

.legal {
    position: absolute;
    background-image: url("/public/images/Legal.png");
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 1.6svh;
    width: 47svh;
    bottom: 3svh;
    left: 50%;
    transform: translateX(-50%);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    display: none;
    margin: 0;
}

