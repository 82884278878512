.authText
{
    position: relative;
    top: 11svh;
    background-image: url("/public/images/authText.png");
    background-size: auto 100%;   
    background-position: center; 
    background-repeat: no-repeat;
    height: 10svh;
}

.userInput
{
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 40svh;
    height: 25svh;    
    margin-top: 11svh;
    justify-content: space-evenly;
    left: 50%;
    transform: translateX(-50%);    
}


.inputField{
    color: white;
    position: relative;    
    height: 5svh;
    font-size: 1.5svh;
    background-image: url("/public/images/inputFieldBG.png");
    background-size: 100% 100%;   
    background-position: center; 
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
    padding: 0 2svh 0 2svh;

}


::placeholder{
    color: white;
    opacity: 1;
}

.TNCAgree
{        
    position: absolute;        
    color: white;
    display: flex;    
    align-items: center;
    flex-direction: row;    
    text-align: center;
    width: 40svh;
    bottom: -4svh;  
    left: 5svh;      
}

#tncCheckbox
{
    width: 3svh;
    height: 3svh;
    margin-left: 10px;
}

.tncText
{        
    margin-left: 6svh;
    font-size: 1.5svh;
}

#tncCloseContainer{
    z-index: 13;
    position: absolute;    
    width: 44svh;
    height: 5svh;
    right: 0;
    top: 13svh;
    left: 50%;
    transform: translateX(-50%);

}

.tncClose{
    z-index: 15;    
    background-image: url("/public/images/close.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 5svh;
    position: absolute;
    right: 3svh;
}

#recaptcha-container{
    /* display: none; */
}

.sendOtpButton
{        
    z-index: 15;
    justify-self: center;   
    bottom: -15svh;     
}

.TNCScroll{
    background-color: rgba(0, 0, 0, 0.37);
    color: white;
    white-space: pre-wrap;
    position: absolute;
    overflow-y: scroll;
    overflow-x: hidden;    
    width: 35svh;
    height: 75svh;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -48%);
    font-size: 1.5svh;
    padding: 2svh;

}

.TNCText{
    white-space: pre-wrap;
}