#participateButton{   
    position: absolute; 
    justify-self: center;
    bottom: 30svh;        
    font-size: 48px;    
    
}


.splashScreenText {
    position: relative;
    top: 12svh;
    background-image: url("/public/images/txt.png");
    background-size: auto 100%;   
    background-position: center; 
    background-repeat: no-repeat;
    height: 12svh;
    width: 50svh;
    left: 50%;
    transform: translateX(-50%);
    
}


.jioCinema{
    
    position: absolute;    
    background-image: url("/public/images/UpdatedLogoJC.png");
    background-size: auto 100%;   
    background-position: center; 
    background-repeat: no-repeat;
    height: 20svh;
    width: 40svh;
    bottom: 12svh;
    left: 50%;
    transform: translateX(-50%);

}

.jioCinemaHome{
    z-index: -1;
    position: absolute;    
    background-image: url("/public/images/UpdatedLogoJC.png");
    background-size: auto 100%;   
    background-position: center; 
    background-repeat: no-repeat;
    height: 23svh;
    width: 40svh;
    bottom: 8svh;
    left: 50%;
    transform: translateX(-50%);

}

