.thankyouText{
    position: relative;
    top: 10svh;
    background-image: url("/public/images/ThankyouText.png");
    background-size: auto 100%;   
    background-position: center; 
    background-repeat: no-repeat;
    height: 30svh;
}

#followUsButton{   
    display: flex;   
    justify-content: center;
    flex-direction: row;
    align-items: center;  
    font-size: 1em;
    bottom: 31svh;
}

.instaLogo{
    position: relative;
    background-image: url("/public/images/instaLogo.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 4svh;
    width: 4svh;
    margin-left: 1svh  ;

}

.shareLinkIcon{
    position: absolute;
    background-image: url("/public/images/ShareIcon.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 2svh;
    width: 2svh;
    right: -3svh;
    bottom: .2svh;

}

.shareLink{
    position: absolute;
    text-align: center;
    z-index: 10;    
    font-size: 2svh;
    color: white;
    left: 50%;
    transform: translateX(-70%);
    bottom: 28svh;
    
}